<template>
    <Popup title="Output">
        <div class="buttons">
            <div class="button">
                <button @click="run()">Upload in all posts</button>
            </div>
            <div class="button">
                <input v-model="oneUrl" type="url" placeholder="Post URL">
                <button @click="run(oneUrl)">Upload one</button>
            </div>
        </div>
        <textarea v-model="output" placeholder="Output..."/>
    </Popup>
</template>

<script setup>
const output = ref("");
const timeout = ref(null);

const oneUrl = ref('');

const sentTime = ref(null);

async function run(url = null) {
    output.value = "Uploading";
    sentTime.value = new Date();
    timeout.value = setInterval(() => {
        output.value = output.value.substring(
            0,
            (output.value.indexOf('(') <= 0 ?
                    output.value.length : output.value.indexOf('(')
            )
        ).trim() + ".";
        if (output.value.match(/\./g).length > 3) {
            output.value = "Uploading";
        }
        output.value = output.value + " (" + ((new Date() - sentTime.value) / 1000).toFixed(2) + "s)";
    }, 200);
    try {
        const data = await $fetch("/api/admin/image_optimize", {
            method: 'POST',
            body: url ? {
                state: 'one',
                url: oneUrl.value
            } : ''
        });
        output.value = data;
    } catch (e) {
        output.value = e.message;
    }
    clearInterval(timeout.value);
}
</script>

<style scoped>
textarea {
    width: 99%;
    height: 70vh;
}

.buttons {
    display: flex;
    justify-content: space-between;

    padding: 1em;

    position: relative;

    &::after {
        position: absolute;
        top: 50%;
        left: 0;
        height: 100%;
        width: 1px;
        background-color: #ccc;
    }
}

.button {
    display: flex;
    gap: .5em;
}
</style>